// Function to compute sentiment bins and labels
import {Article} from "../../types";
import {Box} from "@mui/material";
import {Bar} from "react-chartjs-2";
import React from "react";
import {computeSentimentBins} from "./ComputeSentimentBins";

// SentimentHistogram component
export const SentimentHistogram = ({articles}: { articles: Article[] }) => {
  const binWidth = 0.2; // For main table, bin width 0.2
  const {bins, binLabels} = computeSentimentBins(articles, binWidth);

  // Create the data for Chart.js
  const chartData = {
    labels: binLabels,
    datasets: [
      {
        label: 'Number of Articles',
        data: bins,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  // Chart options without axis labels
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false, // Disable tooltips
      },
    },
    scales: {
      x: {
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          font: {
            size: 10,
          },
        },
        grid: {
          display: false,
        },
        title: {
          display: false, // Remove x-axis label
        },
      },
      y: {
        display: false,
        beginAtZero: true,
        ticks: {
          display: false, // Hide y-axis ticks
        },
        grid: {
          display: false,
        },
        title: {
          display: false, // Remove y-axis label
        },
      },
    },
    maintainAspectRatio: false,
  };

  // Center the histogram within its container
  return (
    <Box display="flex" justifyContent="center">
      <Box style={{width: '150px', height: '100px'}}>
        <Bar data={chartData} options={options}/>
      </Box>
    </Box>
  );
};