// src/api/axiosInstance.ts
import axios, { AxiosError, AxiosResponse } from 'axios';
import { getStoredToken, getStoredRefreshToken, storeTokens, clearTokens } from '../auth/auth';
import { ApiError, AuthError, NotFoundError } from '../errors/ApiError';

// Create Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || '/',
});

let isRefreshing = false;
let failedQueue: Array<{
  resolve: (token: string | null) => void;
  reject: (error: any) => void;
}> = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

let onSessionExpired: (() => void) | null = null;

// Function to set the session expired callback
export const setSessionExpiredCallback = (callback: () => void) => {
  onSessionExpired = callback;
};

// Request interceptor to attach the token
axiosInstance.interceptors.request.use(
  (config) => {
    if (!config.skipAuth) { // Custom flag to skip auth
      const token = getStoredToken();
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  error => Promise.reject(error)
);

// Response interceptor to handle errors and token refresh
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config;

    if (!originalRequest) {
      return Promise.reject(new ApiError('No original request configuration.', undefined, undefined));
    }

    if (error.response) {
      const status = error.response.status;

      if (status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise<string | null>((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then(token => {
              if (originalRequest.headers && token) {
                originalRequest.headers.Authorization = `Bearer ${token}`;
              }
              return axiosInstance(originalRequest);
            })
            .catch(err => Promise.reject(err));
        }

        originalRequest._retry = true;
        isRefreshing = true;

        const refreshToken = getStoredRefreshToken();
        if (!refreshToken) {
          clearTokens();
          if (onSessionExpired) {
            onSessionExpired();
          }
          return Promise.reject(new AuthError('No refresh token available.'));
        }

        try {
          const response = await axiosInstance.post('/api/auth/refresh', { refresh_token: refreshToken }, { skipAuth: true });
          const newAccessToken = response.data.access_token;
          const newRefreshToken = response.data.refresh_token || refreshToken;

          storeTokens(newAccessToken, newRefreshToken);
          axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;

          processQueue(null, newAccessToken);
          return axiosInstance(originalRequest);
        } catch (refreshError: any) {
          processQueue(refreshError, null);
          clearTokens();
          if (onSessionExpired) {
            onSessionExpired();
          }
          return Promise.reject(
            new AuthError('Token refresh failed.', refreshError.response?.status, refreshError.response?.data)
          );
        } finally {
          isRefreshing = false;
        }
      }

      if (status === 404) {
        return Promise.reject(new NotFoundError('The requested resource was not found.', error.response.data));
      }

      return Promise.reject(new ApiError(error.message, status, error.response.data));
    }

    return Promise.reject(new ApiError(error.message));
  }
);

export default axiosInstance;