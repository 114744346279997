import {Article} from "../../types";

export function computeSentimentBins(articles: Article[], binWidth: number) {
  const sentimentScores = articles.map((article) => article.sentiment_score || 0);
  const numBins = Math.ceil(1 / binWidth);
  const bins = new Array(numBins).fill(0);

  sentimentScores.forEach((score) => {
    let binIndex = Math.floor(score / binWidth);
    if (binIndex >= numBins) {
      binIndex = numBins - 1; // Edge case when score is exactly 1
    }
    bins[binIndex]++;
  });

  const binLabels = [];
  for (let i = 0; i < numBins; i++) {
    const limit = ((i + 1) * binWidth).toFixed(1);
    binLabels.push(`<${limit}`);
  }
  return {bins, binLabels};
}