import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {AccountCircle} from '@mui/icons-material';
import {handleProfilePicUpdate} from "./HandleProfilePicUpdate";
import axiosInstance from "../../api/axiosInstance";
import {api} from "../../api/apiService";

const ProfilePicture = () => {
  const [profilePic, setProfilePic] = useState<string | null>(null); // Profile picture URL or null
  const [isLoading, setIsLoading] = useState<boolean>(true); // Loading state

  useEffect(() => {
    const fetchProfilePic = async () => {
      try {
        const response = await api.get(`/api/users/me/profile-picture`, {responseType: 'blob'});
          const imageUrl = URL.createObjectURL(response);
          setProfilePic(imageUrl);
      } catch (error) {
        console.error('Error fetching profile picture:', error);
        setProfilePic(null); // Handle fetch error
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfilePic();
  }, []);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <label htmlFor="profile-pic-upload">
        {isLoading ? (
          <div>Loading...</div>
        ) : profilePic ? (
          <img
            src={profilePic}
            alt="Profile"
            style={{width: '150px', height: '150px', borderRadius: '50%', cursor: 'pointer'}}
          />
        ) : (
          <AccountCircle style={{fontSize: 150, cursor: 'pointer'}}/>
        )}
      </label>
      <input
        id="profile-pic-upload"
        type="file"
        accept="image/*"
        style={{display: 'none'}}
        onChange={(e) => handleProfilePicUpdate(e, setProfilePic)} // Pass setProfilePic here
      />
    </Box>
  );
};

export default ProfilePicture;